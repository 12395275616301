export const Assets = {
  Logo: require("./navLogo.jpg"),
  LogoWebp: require("./navLogo.webp"),
  Img1: {
    small: {
      src: require("./1.jpg"),
      srcWebp: require("./1.webp"),
    },
    panoramic: {
      src: require("./1p.jpg"),
      srcWebp: require("./1p.webp"),
    },
    tall: {
      src: require("./1t.jpg"),
      srcWebp: require("./1t.webp"),
    },
    big: {
      src: require("./1b.jpg"),
      srcWebp: require("./1b.webp"),
    },
  },
  Img2: {
    small: {
      src: require("./2.jpg"),
      srcWebp: require("./2.webp"),
    },
    panoramic: {
      src: require("./2p.jpg"),
      srcWebp: require("./2p.webp"),
    },
    tall: {
      src: require("./2t.jpg"),
      srcWebp: require("./2t.webp"),
    },
    big: {
      src: require("./2b.jpg"),
      srcWebp: require("./2b.webp"),
    },
  },
  Img3: {
    small: {
      src: require("./3.jpg"),
      srcWebp: require("./3.webp"),
    },
    panoramic: {
      src: require("./3p.jpg"),
      srcWebp: require("./3p.webp"),
    },
    tall: {
      src: require("./3t.jpg"),
      srcWebp: require("./3t.webp"),
    },
    big: {
      src: require("./3b.jpg"),
      srcWebp: require("./3b.webp"),
    },
  },
  CarrImg1: {
    src: require("./c1.jpg"),
    srcWebp: require("./c1.webp"),
    srcType: "jpg",
  },
  CarrImg2: {
    src: require("./c2.jpg"),
    srcWebp: require("./c2.webp"),
    srcType: "jpg",
  },
  CarrImg3: {
    src: require("./c3.jpg"),
    srcWebp: require("./c3.webp"),
    srcType: "jpg",
  },
  CarrImg4: {
    src: require("./c4.jpg"),
    srcWebp: require("./c4.webp"),
    srcType: "jpg",
  },
  Servicios: {
    panoramic: {
      src: require("./foto-servicios.jpg"),
      srcWebp: require("./foto-servicios.webp"),
      srcType: "jpg",
    },
    big: {
      src: require("./foto-servicios-big.jpg"),
      srcWebp: require("./foto-servicios-big.webp"),
      srcType: "jpg",
    },
  },
  mailIcon: require("./mailIcon.svg"),
  mapIcon: require("./map-marker.png"),
  earphoneIcon: require("./earphone.png"),
  smartphoneIcon: require("./smartphone.png"),
  envelopeIcon: require("./envelope.png"),
  facebookIcon: require("./facebook.svg"),
  instagramIcon: require("./instagram.svg"),
  linkedIcon: require("./linkedin.svg"),
  arrowUp: require("./arrow-up.svg"),
  leftArrow: require("./left-arrow.svg"),
  rightArrow: require("./right-arrow.svg"),

  ana: require("./ana.jpg"),
  franky: require("./franky.jpg"),
  semi: require("./semi.jpg"),
  team: {
    big: require("./team-big.jpg"),
    bigWebp: require("./team-big.webp"),
    small: require("./team-small.jpg"),
    smallWebp: require("./team-small.webp"),
  },
}
