import React from "react"

export default function JumboLogo() {
  return (
    <div className="logoContainer">
      <svg
        width="873"
        height="833"
        viewBox="0 0 873 833"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M234.095 279.337L234.34 285.099L240.107 285.083C298.88 284.919 379.582 258.441 452.467 212.148C506.083 178.105 546.038 139.371 567.009 103.937C577.489 86.2276 583.46 68.9476 583.637 53.2521C583.818 37.3207 578 23.2415 565.486 12.6524L565.484 12.6508C563.545 11.0117 561.485 9.48952 559.312 8.08029C587.099 11.8929 610.004 20.7092 626.234 34.443L626.235 34.4435C641.567 47.4142 649.676 63.6768 651.384 82.1692C653.107 100.812 648.344 122.079 637.201 144.746C614.891 190.124 567.587 239.875 500.074 282.748L500.073 282.748C415.004 336.775 319.193 366.315 244.034 367.023L237.831 367.081L238.095 373.279L238.781 389.328L180.214 337.209L233.689 269.776L234.095 279.337Z"
          stroke="#00A4D5"
          strokeWidth="17"
        />
        <path
          d="M656.777 403.493L653.193 397.955L648.019 402.046C612.342 430.255 568.451 457.488 517.569 481.851L520.16 487.263L517.568 481.851C419.52 528.807 314.482 556.082 224.048 562.268C178.834 565.361 137.431 563.171 102.461 555.629C67.4406 548.077 39.257 535.244 20.0372 517.36L20.031 517.354L20.0248 517.349C19.4275 516.796 18.8363 516.233 18.2507 515.663C10.6607 484.59 6.44894 452.19 6.03395 418.876C10.2231 424.988 15.2797 430.765 21.2088 436.168C61.6193 473.007 135.355 485.958 220.299 478.799C305.66 471.605 403.955 443.988 495.116 397.713C536.166 376.875 572.234 354.005 602.842 330.22L607.187 326.843L604.197 322.223L592.625 304.343L689.655 311.871L666.233 418.104L656.777 403.493ZM408.799 126.829L404.045 124.491L401.136 128.917C385.244 153.097 349.243 177.74 299.865 193.6C258.737 206.81 216.879 211.171 182.579 207.654C147.853 204.094 122.58 192.679 112.211 176.171L112.211 176.171C103.522 162.341 105.85 145.554 119.467 127.73C130.418 113.395 148.274 99.1012 171.677 86.7706C167.765 90.7808 164.569 94.9179 162.224 99.1188C156.882 108.692 155.753 119.159 161.618 128.503L161.62 128.505C165.834 135.212 172.871 140.026 181.185 143.323C189.546 146.638 199.661 148.611 210.76 149.346C232.964 150.816 259.921 147.384 286.526 138.835C312.547 130.475 333.961 118.599 347.575 105.71L353.842 99.7766L346.098 95.9685L340.264 93.1002L405.143 80.2343L423.175 133.901L408.799 126.829Z"
          stroke="#171963"
          strokeWidth="17"
        />
        <path
          d="M807.211 362.081C829.186 330.887 842.445 300.62 844.752 273.74C856.169 306.912 863.631 341.916 866.579 378.2C847.184 433.857 802.219 494.576 732.459 551.442C616.84 645.673 464.225 703.847 336.091 711.092L328.499 711.522L330.683 718.806L334.603 731.882L248.047 690.126L300.338 617.499L305.322 634.144L306.73 638.846L311.618 638.398C411.388 629.255 542.274 579.669 655.911 502.486C723.496 456.583 775.116 407.642 807.211 362.081Z"
          stroke="#00A4D5"
          strokeWidth="17"
        />
        <path
          d="M744.533 685.63L742.086 676.306L734.801 682.618C704.401 708.957 657.568 739.826 611.107 761.359L611.107 761.359C514.94 805.938 412.755 826.521 316.514 826.394C266.905 812.02 220.979 788.977 180.495 759.02C227.287 774.78 285.183 780.85 346.624 777.72C427.228 773.614 514.644 753.643 592.439 718.172L592.44 718.172C650.531 691.68 695.742 659.932 726.19 626.599L728.393 624.188L727.563 621.029L724.836 610.643L779.636 612.165L744.628 685.988L744.533 685.63Z"
          stroke="#171963"
          strokeWidth="17"
        />
      </svg>
    </div>
  )
}
