import React from "react"

export default function WebPicture(props) {
  return (
    <picture className={props.className}>
      <source srcSet={props.srcWebp} type="image/webp" />
      <source srcSet={props.src} type={"image/" + props.srcType} />
      <img
        src={props.src}
        alt={props.alt}
        id={props.id}
        onClick={props.onClick}
      />
    </picture>
  )
}
