import React, { useEffect, useState } from "react"
import NavigationBar from "./NavBar/NavigationBar"
import Jumbo from "./Jumbo/Jumbo"
import LegalButtons from "./LegalButtons"
import "../App.min.css"
import "bootstrap/dist/css/bootstrap.min.css"
import Head from "../components/Head"

export const Layout = props => {
  const [path, setPath] = useState("/")

  useEffect(() => {
    setPath(document.location.pathname)
  }, [])
  return (
    <div>
      <Head />
      <NavigationBar />
      {props.Jumbo && <Jumbo />}
      {props.children}
      <LegalButtons path={path} />
    </div>
  )
}
