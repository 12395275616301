import React from "react"
import JumboLogo from "./JumboLogo"

const Jumbo = props => {
  return (
    <>
      <div className="jumbo">
        <div className="jumboContent">
          <h1>SEMTE Instalaciones</h1>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <JumboLogo />
          </div>
          <p>Climatización, tratamientos de aire y ventilación.</p>
        </div>
      </div>
    </>
  )
}

export default Jumbo
