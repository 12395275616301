import React, { useEffect } from "react"
import { Assets } from "../../assets"
import { Link } from "gatsby"
import WebPicture from "../WebPicture"

const NavigationBar = props => {
  const handleScroll = () => {
    if ((window.pageYOffset || document.documentElement.scrollTop) > 1) {
      document.querySelector(".navBar").classList.add("shrink")
    } else {
      document.querySelector(".navBar").classList.remove("shrink")
    }
  }

  const handleNavOpen = () => {
    let navBar = document.querySelector(".navBar").classList
    if (navBar.contains("nav-open")) {
      navBar.toggle("closing")
      setTimeout(() => {
        navBar.toggle("closing")
      }, 1000)
    }
    navBar.toggle("nav-open")
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <nav className="navBar">
      <Link to="/#" className="nav-brand">
        <WebPicture
          src={Assets.Logo}
          srcWebp={Assets.LogoWebp}
          srcType="jpg"
          alt="brand logo"
          id="nav-logo"
        />
        <p>SEMTE</p>
      </Link>
      <ul>
        <li>
          <Link to="/#about" onClick={handleNavOpen}>
            origenes
          </Link>
        </li>
        <li>
          <Link to="/#trabajos" onClick={handleNavOpen}>
            trabajos
          </Link>
        </li>
        <li>
          <Link to="/#contacto" onClick={handleNavOpen}>
            contacto
          </Link>
        </li>
        <li>
          <Link to="/galeria" onClick={handleNavOpen}>
            fotos
          </Link>
        </li>
      </ul>

      <button className="nav__toggle" onClick={handleNavOpen}>
        <span className="hamburger"></span>
      </button>
    </nav>
  )
}

export default NavigationBar
