import React from "react"
import { Link } from "gatsby"

export default function LegalButtons(props) {
  return (
    <div className="legalButtons">
      {props.path !== "/aviso-legal" && (
        <Link to="/aviso-legal" className="btn">
          Aviso legal
        </Link>
      )}
      {props.path !== "/proteccion-de-datos" && (
        <Link to="/proteccion-de-datos" className="btn">
          Protección de datos
        </Link>
      )}
    </div>
  )
}
